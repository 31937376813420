import React from 'react';
import { styled } from '@mui/material/styles';

const SkipToContentLink = styled('a')(() => ({
  position: 'absolute',
  top: '-40px',
  left: '0',
  background: '#0969da',
  color: '#fff',
  padding: '10px',
  zIndex: '100000',
  fontSize: '14px',
  '&:focus': {
    top: '0'
  }
}));

const SkipToContent = () => {
  return (
    <SkipToContentLink href="#main-content">
      Skip to main content
    </SkipToContentLink>
  );
};

export default SkipToContent;
