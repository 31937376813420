import { css } from '@emotion/react';

// Mobile styles for the deal card
// container (max-width: 1200px)
// Desktop styles for the deal card
// @container (min-width: 1600px)
const globalStyles = css`
  .page-deal-lead-image {
    margin: 0 auto !important;
    max-width: 100% !important;
    width: 600px !important;
    height: 400px !important;
    transform: initial !important;

    @container (min-width: 501px) and (max-width: 600px) {
      width: 500px !important;
      height: 375px !important;
    }
    @container (min-width: 401px) and (max-width: 500px) {
      width: 400px !important;
      height: 300px !important;
    }
    @container (min-width: 351px) and (max-width: 400px) {
      width: 350px !important;
      height: 262.5px !important;
    }
    @container (min-width: 301px) and (max-width: 350px) {
      width: 300px !important;
      height: 225px !important;
    }
    @container (max-width: 300px) {
      width: 250px !important;
      height: 187.5px !important;
      max-width: 100% !important;
    }
  }

  @container (min-width: 700px) {
    .deal-card-inner-container {
      display: flex;
      padding-top: 16px;
    }

    .deal-card-media-image {
      display: flex;
    }

    .deal-card-image-skeleton {
      display: flex;
    }

    .deal-card-spacer {
      display: block;
      min-height: 38px;
    }

    .deal-card-image-box {
      min-width: 230px;
      margin-top: 0px;
    }

    .deal-card-content {
      // min-width: calc(100% - 282px);
      margin: 0px 0px 16px 0px !important;
      width: 100% !important;
      padding: 0px !important;
    }
  }
`;

export default globalStyles;
