import CelebrationIcon from '@mui/icons-material/Celebration';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import {
  isPrimeDay,
  isLaborDay,
  isBeforeLaborDay,
  isBeforePrimeDay
} from '@utils/index';
import config from '@configFile';
import colors from './colors';

interface EventStrObj {
  label: string;
  value: string;
  showCountdown: boolean;
  isEarlySale: boolean;
  listingMode: string;
  dealHighlightColor: string;
  chipSelectedColor: string;
  Icon: React.ElementType;
  iconName: string;
  // used for blog page title
  prefix?: string;
  shouldShowDeals: boolean;
  // whether to sho the prifix
  showSitePrefix?: boolean;
}

const currentYear = new Date().getFullYear();

export const getCurrentDealEventStrObj = (): EventStrObj | null => {
  if (isPrimeDay() || config.IS_PRIME_RUN_UP) {
    return {
      label: isPrimeDay() ? 'Prime Day' : 'Early Prime Day',
      value: 'PRIME_DAY',
      showCountdown: true,
      isEarlySale: config.SHOW_EARLY_DEALS || !isPrimeDay(),
      listingMode: `PRIME_DAY_${currentYear}`,
      dealHighlightColor: colors.jdbPurple,
      chipSelectedColor: colors.primeSelected,
      Icon: CelebrationIcon,
      iconName: 'CelebrationIcon',
      prefix: isPrimeDay() ? 'Prime Day' : 'Early Prime Day',
      showSitePrefix: true,
      shouldShowDeals: true
    };
  }

  if (isLaborDay()) {
    return {
      label: 'Labor Day',
      value: 'LABOR_DAY',
      showCountdown: true,
      isEarlySale: false,
      listingMode: `LABOR_DAY_${currentYear}`,
      dealHighlightColor: '#000000',
      chipSelectedColor: '#FFFFFF',
      Icon: LoyaltyIcon,
      iconName: 'LoyaltyIcon',
      shouldShowDeals: true,
      showSitePrefix: true
    };
  }

  return null;
};

// used on backend for future product database additions
// if the asin exists in the event deals database table
export const getFutureEventListingMode = (): string => {
  if (isBeforePrimeDay() || isPrimeDay() || config.IS_PRIME_RUN_UP) {
    return `PRIME_DAY_${currentYear}`;
  }

  if (isBeforeLaborDay() || isLaborDay()) {
    return `LABOR_DAY_${currentYear}`;
  }

  return 'UNKNOWN';
};

// used for day of database additions for listing more
export const getCurrentEventListingMode = (): string => {
  // if something is added on prime day, just count it as a prime day product
  if (isPrimeDay()) {
    return `PRIME_DAY_${currentYear}`;
  }

  return 'UNKNOWN';
};

export const hasFutureOrCurrentEvent = (): boolean => {
  return (
    isBeforeLaborDay() || isBeforePrimeDay() || isPrimeDay() || isLaborDay()
  );
};
