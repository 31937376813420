/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import { SuggestionType } from '../types';

export function useSearchWebsite() {
  interface Request {
    term?: string;
  }

  const mutationFn = async ({ term }: Request): Promise<SuggestionType[]> => {
    const results = await axios.post(`/api/search-website`, {
      term
    });

    return results.data;
  };

  return useMutation(mutationFn, {
    onError: () => {
      console.error('error searching');
    }
  });
}

interface RequestData {
  term?: string;
}

export function useGetSearch(
  data: RequestData
): UseQueryResult<SuggestionType[]> {
  const queryFn = async ({ term }: RequestData): Promise<SuggestionType[]> => {
    if (!term) {
      return [];
    }

    const result = await axios.post(`/api/search-website`, {
      term
    });
    return result.data;
  };

  return useQuery<SuggestionType[], Error>(
    `banned-terms`,
    () => queryFn(data),
    {
      onError: () => {
        console.error('Something went wrong while fetching the banned-terms');
      }
    }
  );
}
