/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import { loadableReady } from '@loadable/component';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { QueryClientProvider } from 'react-query';
import config from '@configFile';
import createEmotionCache from './createEmotionCache';
import { SnackbarProvider } from './hooks/useSnackbar';
import { LoginModalProvider } from './hooks/useLoginModal';
import theme from './theme/theme';
import queryClient from './queryClient';

import configureStore from './utils/configureStore';
import routes from './routes';

// Create the emotion cache
const cache = createEmotionCache();

// Get the initial state from server-side rendering
const initialState = window.__INITIAL_STATE__;
const { store, history } = configureStore({ initialState });

const render = (Routes: Array<object>) => {
  const renderMethod = (module as any).hot ? ReactDOM.render : ReactDOM.hydrate;
  renderMethod(
      <GoogleOAuthProvider clientId="135329434554-k4db2qolei4ej44835kv5hq05tupvaij.apps.googleusercontent.com">
        <SnackbarProvider>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <QueryClientProvider client={queryClient}>
                <CacheProvider value={cache}>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={theme}>
                        <LoginModalProvider>
                          {renderRoutes(Routes)}
                        </LoginModalProvider>
                      </ThemeProvider>
                    </StyledEngineProvider>
                </CacheProvider>
              </QueryClientProvider>
            </ConnectedRouter>
          </Provider>
        </SnackbarProvider>
      </GoogleOAuthProvider>,
    document.getElementById('react-view')
  );
};

// loadable-component setup
loadableReady(() => {
  render(routes);
});

if ((module as any).hot) {
  // Enable webpack hot module replacement for routes
  (module as any).hot.accept('./routes', () => {
    try {
      const nextRoutes = require('./routes').default;

      render(nextRoutes);
    } catch (error) {
      console.error(`==> 😭  Routes hot reloading error ${error}`);
    }
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
function register() {
  if ('serviceWorker' in navigator && config.USE_SERVICE_WORKER) {
   // @ts-ignore
    window.self.__WB_DISABLE_DEV_LOGS = false;

    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/assets/sw.js')
        .then((registration) => {
          // console.log('SW registered: ', registration);

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;

            if (!installingWorker) return;

            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  console.log(
                    'New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA.',
                  );

                  if (!!window?.localStorage?.getItem('googleLogin') && window.confirm(`New app update is available!. Click OK to refresh`)) {
                    window.location.reload();
                  }
                } else {
                  console.log('Content is cached for offline use.');
                }
              }
            };
          };
        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
}

register();

// export function unregister() {
//   if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.ready
//       .then((registration) => registration.unregister())
//       .catch((err) => console.error(err.message));
//   }
// }