const isStringAllTheSameCharacter = (str: string): boolean => {
  return str.split('').every((char) => char === str[0]);
};

export function removeNonAlphanumeric(input: string): string {
  return input.replace(/[^a-zA-Z0-9]/g, '');
}

export const isNameProhibited = (
  firstName: string,
  lastName: string
): boolean => {
  const firstLower = firstName.toLowerCase();
  const lastLower = lastName.toLowerCase();

  if (firstLower.length < 2 || lastLower.length < 2) {
    return false;
  }

  const prohibitedNames = new Set([
    'john doe',
    'me me',
    'name last',
    'first last',
    'john do',
    'jane doe',
    'jane do',
    'test test',
    'jungle deals',
    'fuck you',
    'first last'
  ]);

  const keywords = ['blog', 'jungle', 'deals', 'fuck', 'cunt', 'asshole'];

  if (
    keywords.includes(firstLower) ||
    keywords.includes(lastLower) ||
    keywords.some((keyword) => firstLower.includes(keyword)) ||
    keywords.some((keyword) => lastLower.includes(keyword))
  ) {
    return true;
  }

  if (isStringAllTheSameCharacter(`${firstLower.trim()}${lastLower.trim()}`)) {
    return true;
  }

  return prohibitedNames.has(`${firstLower} ${lastLower}`);
};

export const isPasswordOkay = (password: string) => {
  let isOkay = true;
  let passwordLengthOkay = true;
  let passwordNumberOkay = true;
  if (!password || password.length < 12) {
    isOkay = false;
    passwordLengthOkay = false;
  }

  if (!/\d/.test(password)) {
    isOkay = false;
    passwordNumberOkay = false;
  }

  return {
    isOkay,
    passwordNumberOkay,
    passwordLengthOkay
  };
};
