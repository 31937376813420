import React, { useEffect } from 'react';
import { formatDistance } from 'date-fns';
import { connect, useDispatch } from 'react-redux';
import {
  Card,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Link
} from '@mui/material';
import config from '@configFile';
import AddMailingList from '@components/AddMailingList';
import { getTitleFromPageDeal } from '@utils/index';
import { PageDeal } from '@api/database/models/pageDeal';
import { AppState } from '@types';
import { dealsAction } from '../../../../actions';

const WideImageLinkList = ({ links }: { links: PageDeal[] }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dealsAction.getPageDealSlugs());
  }, [dispatch]);

  return (
    <List>
      {links.map((link) => (
        <ListItem
          key={link.title}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px'
          }}
        >
          <ListItemText
            secondary={`Posted ${formatDistance(
              new Date(link.postDate),
              new Date(),
              {
                addSuffix: true
              }
            )} — ${link.numLiveDeals} deals`}
          >
            <Link href={`/p/${link.slug}`} rel="noopener noreferrer">
              <Typography variant="subtitle1" component="h3">
                {getTitleFromPageDeal(link)}
              </Typography>
            </Link>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

const FeedSidebar = ({ links = [] }: { links: PageDeal[] }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(dealsAction.getPageDealSlugs());
  }, [dispatch]);

  if (!links || links?.length === 0) {
    return null;
  }

  const orderedSlugs = (links || [])
    ?.sort((a, b) => {
      return new Date(b.postDate).getTime() - new Date(a.postDate).getTime();
    })
    .slice(0, 24);

  return (
    <Box position="sticky" top="16px">
      <Card
        sx={{
          margin: '1rem 0rem 1rem 1rem',
          padding: '1rem',
          minHeight: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)'
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Recently Updated Lists
        </Typography>
        <Divider />
        <Box
          sx={{
            overflow: 'scroll',
            height: 'calc(100vh - 100px)'
          }}
        >
          {links ? <WideImageLinkList links={orderedSlugs || []} /> : null}
        </Box>
        <Box
          display={{
            xs: 'none',
            md: 'block'
          }}
        >
          <AddMailingList
            mailingListId={config.sendGrid.mailingLists.dailyDeals}
          />
        </Box>
      </Card>
    </Box>
  );
};

const mapStateToProps = ({ home: { pageDealSlugsSEO } }: AppState) => {
  return {
    links: [...pageDealSlugsSEO]
  };
};

export default connect(mapStateToProps)(FeedSidebar);
