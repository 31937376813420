import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';
import LiveUpdates from '@pages/PrimeDay/LiveUpdates';
import VerifyBanner from './VerifyBanner';

interface PageContainer {
  children: React.ReactNode;
  title?: string;
  fullWidth?: boolean;
  isAdmin?: boolean;
  isSubPageContainer?: boolean;
  isLive?: boolean;
  showContainer?: boolean;
  iconSpace?: number;
  paddingTop?: string;
}

export default (props: PageContainer) => {
  const {
    children,
    title = null,
    fullWidth,
    isAdmin,
    isSubPageContainer,
    showContainer = true,
    isLive = false,
    iconSpace = 30,
    paddingTop = '1rem'
  } = props;

  const classNameForLinks = fullWidth
    ? {
        '@media (max-width: 48rem)': {
          width: 'inherit',
          margin: '0px auto'
        },
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        border: '1px solid #bdc6ca',
        margin: '0',
        position: 'relative'
      }
    : {
        backgroundColor: '#fff',
        border: '1px solid #bdc6ca',
        width: '80%',
        margin: '1rem auto',
        position: 'relative'
      };

  if (!showContainer) {
    return <>{children}</>;
  }

  const activeLinkFunc = (isActive: boolean) => {
    return isActive
      ? {
          color: '#bdc6ca',
          textDecoration: 'none'
        }
      : {
          paddingRight: '10px',
          color: 'black',
          textDecoration: 'none'
        };
  };

  return (
    <>
      {!isSubPageContainer && <VerifyBanner />}
      {isAdmin && (
        <Box
          sx={{
            padding: '1rem',
            paddingTop,
            ...classNameForLinks
          }}
        >
          <NavLink to="/make-lists" style={activeLinkFunc}>
            Make Lists
          </NavLink>
          <NavLink to="/toy-deals-admin" style={activeLinkFunc}>
            Toy deals
          </NavLink>
          <NavLink to="/users" style={activeLinkFunc}>
            Users
          </NavLink>
        </Box>
      )}

      <Box
        data-testid="page-container"
        id="main-content"
        sx={{
          padding: '1rem',
          paddingTop,
          ...classNameForLinks
        }}
      >
        {isLive ? (
          <Box
            justifyContent="flex-end"
            sx={{
              display: 'flex',
              position: 'absolute',
              top: '17px',
              right: '30px',
              '@media (max-width: 48rem)': {
                width: '100% !important',
                position: 'static',
                display: 'flex',
                justifyContent: 'flex-end'
              },
              '@media (max-width: 800px)': {
                display: 'none'
              }
            }}
          >
            <LiveUpdates showText />
          </Box>
        ) : null}
        {title && (
          <Box
            component="h1"
            sx={{
              color: '#000',
              fontFamily: "'Open Sans', 'Helvetica Neue', sans-serif",
              fontSize: '22px',
              marginTop: '0',
              display: 'inline-block',
              width: isLive
                ? 'calc(100% - 180px)'
                : `calc(100% - ${iconSpace}px)`,
              '@media (max-width: 48rem)': {
                width: `calc(100% - ${iconSpace}px)`
              }
            }}
          >
            {title}
          </Box>
        )}
        {children}
      </Box>
    </>
  );
};
