import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import colors from '@utils/colors';
import config from '@configFile';
// import FooterLinks from './components/FooterLinks';

const Footer = () => {
  return (
    <Box
      sx={{
        display: 'block',
        backgroundColor: colors.jdbPurple,
        color: 'white',
        padding: '20px',
        borderTop: '1px solid rgba(0,0,0,0.12)'
      }}
    >
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '82rem',
            xl: '82rem'
          },
          textAlign: 'right'
        }}
      >
        <Typography
          sx={{ fontSize: '16px', marginRight: '16px', marginTop: '16px' }}
        >
          &copy; {new Date().getFullYear()} {config.app.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
