import React, { useState, useCallback } from 'react';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton, Box, Popover, Button, Badge } from '@mui/material';
import { format } from 'date-fns';
import { logPostHogEvent } from '@utils/index';
import useLocalStorage from '@hooks/useLocalStorage';
import trackUse from '@utils/trackUse';
import EmailListUI from './components/EmailListUI';

const EmailLists = () => {
  const currentQuarter = format(new Date(), 'qqq—yyyy');
  const [hasCheckedEmail, setHasCheckedEmail] = useLocalStorage(
    `check-email-quarterly-check—${currentQuarter}`,
    false
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopperOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      trackUse({
        item: 'open-mailing-popper-app-bar',
        value: 'open',
        type: 'CLICK'
      });
      logPostHogEvent('open-mailing-popper-app-bar', {
        value: 'open',
        type: 'CLICK'
      });
      setHasCheckedEmail(true);
    },
    [setHasCheckedEmail]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ display: 'inline', marginRight: '12px' }}>
      <Popover
        id="email-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          sx: {
            minWidth: '600px',
            maxWidth: '95%',
            '@media (max-width: 640px)': {
              maxWidth: '95%',
              width: '550px',
              minWidth: '95%'
            }
          }
        }}
      >
        <Box
          sx={{
            minWidth: '600px',
            maxWidth: '95%',
            '@media (max-width: 640px)': { width: '95%', minWidth: '95%' }
          }}
        >
          <EmailListUI />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ margin: '0px 8px 8px 8px' }}
        >
          <Button onClick={handleClose}>Close</Button>
        </Box>
      </Popover>
      <Badge badgeContent={hasCheckedEmail ? 0 : 1} color="error">
        <IconButton
          aria-label="See subscribed mailing lists"
          onClick={handlePopperOpen}
          size="small"
        >
          <MailIcon sx={{ color: '#fff' }} />
        </IconButton>
      </Badge>
    </Box>
  );
};

export default EmailLists;
