import React from 'react';
// import X from "@mui/icons-material/X" TODO: Add X icon
// import TwitterIcon from '@mui/icons-material/Twitter';
import { FaTiktok } from 'react-icons/fa';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Box, IconButton, Tooltip } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { trackLinkClick } from '../../../utils';
import { iconButtonHoverRaiseStyle } from '../../../utils/styles';

const socialUrls = [
  {
    name: 'Facebook Group',
    url: 'https://www.facebook.com/groups/Jungledealsblog/',
    icon: (
      <FacebookIcon
        sx={{
          color: '#3b5998'
        }}
      />
    )
  },
  {
    name: 'Facebook Page',
    url: 'https://www.facebook.com/JungleDealsBlog/',
    icon: (
      <FacebookIcon
        sx={{
          color: '#3b5998'
        }}
      />
    )
  },
  {
    name: 'Follow on TikTok',
    url: 'https://www.tiktok.com/@jungledealsblog',
    icon: <FaTiktok />
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/jungle.deals/',
    icon: (
      <InstagramIcon
        sx={{
          color: '#e95950'
        }}
      />
    )
  }
  // {
  //   name: 'X — (Formerly Twitter)',
  //   url: 'https://twitter.com/jungledealsblog',
  //   icon: (
  //     <TwitterIcon
  //       sx={{
  //         color: '#14171A'
  //       }}
  //     />
  //   )
  // }
];

const SocialIcons = () => {
  return (
    <Box>
      {socialUrls.map((socialUrl) => (
        <Tooltip title={socialUrl.name} key={socialUrl.name} placement="top">
          <IconButton
            aria-label={socialUrl.name}
            href={socialUrl.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              trackLinkClick(e, `social-icons-${socialUrl.name}`)
            }
            sx={iconButtonHoverRaiseStyle}
          >
            {socialUrl.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

export default SocialIcons;
