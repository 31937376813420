/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import Autosuggest, {
  SuggestionSelectedEventData,
  InputProps
} from 'react-autosuggest';
import {
  Typography,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  CircularProgress,
  Box,
  useTheme
} from '@mui/material';
import {
  Lock,
  Sell,
  ViewList,
  Search as SearchIcon
} from '@mui/icons-material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSearchWebsite } from '@hooks/useSearch';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import { SuggestionType } from '@types';
import { dealsAction } from '../../actions';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
  NoResultOptions,
  getSuggestionValue
} from './utils';

const SearchBox = ({
  autoFocus = false,
  callback = () => {}
}: {
  callback?: () => void;
  autoFocus?: boolean;
  showAll?: boolean;
}) => {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const { mutate: searchWebsite, isLoading: isLoadingResults } =
    useSearchWebsite();

  const renderInputComponent = (inputProps: any) => {
    return (
      <Search>
        <SearchIconWrapper>
          {isLoadingResults ? (
            <CircularProgress
              size={18}
              sx={{
                color: `${theme.palette.primary.contrastText} !important`
              }}
            />
          ) : (
            <SearchIcon />
          )}
        </SearchIconWrapper>
        <StyledInputBase
          {...inputProps}
          inputProps={{
            ...inputProps.inputProps,
            'aria-label': 'search', // Keeps label for screen readers
            'aria-expanded': Boolean(suggestions.length), // Reflects if the suggestion list is open
            'aria-autocomplete': 'list', // Indicates autocomplete behavior
            'aria-controls': 'react-autowhatever-1' // Points to the dropdown menu
          }}
        />
      </Search>
    );
  };
  const debouncedSearch = useCallback(
    _.debounce(async ({ term }: { term: string }) => {
      if (term.length >= 3) {
        searchWebsite(
          { term },
          {
            onSuccess: (data) =>
              setSuggestions(data.length > 0 ? data : NoResultOptions)
          }
        );
      }
    }, 300),
    [searchWebsite]
  );

  const handleSuggestionsFetchRequested = ({ value }: { value: string }) => {
    if (value.length >= 3) debouncedSearch({ term: value });
  };

  const handleSuggestionsClearRequested = () => setSuggestions([]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    { newValue }: { newValue: string }
  ) => setValue(newValue);

  const onSuggestionSelected = (
    _event: React.FormEvent<any>,
    { suggestion }: SuggestionSelectedEventData<SuggestionType>
  ) => {
    trackUse({
      item: 'search-bar-suggestion-click',
      value: `${value} - ${suggestion.url} - ${suggestion.title}`,
      type: 'CLICK'
    });

    logPostHogEvent('search-bar-suggestion-click', {
      value: `${value} - ${suggestion.url} - ${suggestion.title}`,
      type: 'CLICK'
    });

    if (!suggestion.url) {
      setValue('');
      return;
    }

    if (suggestion.isAdmin) {
      window.location.href = suggestion.url;
    } else {
      history.push(suggestion.url);
      dispatch(dealsAction.getDealSummary(suggestion.ASIN ?? ''));
      dispatch(dealsAction.getBlogSEO(suggestion.slug ?? ''));
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 250);
    }
    setValue('');
    callback();
  };

  const inputProps: InputProps<SuggestionType> = {
    placeholder: 'Search deals...',
    value,
    onChange: handleChange,
    autoFocus
  };

  const highlightedStyle = { fontWeight: 500 } as React.CSSProperties;
  const unHighlightedStyle = { fontWeight: 300 } as React.CSSProperties;

  return (
    <Box marginTop="12px" marginBottom="12px">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        renderInputComponent={renderInputComponent}
        theme={{
          container: {
            position: 'relative',
            width: '100%'
          },
          suggestionsList: {
            listStyleType: 'none',
            paddingInlineStart: '0px',
            backgroundColor: theme.palette.background.paper,
            position: 'fixed',
            zIndex: 1000,
            maxHeight: '300px',
            // bottom shadow 1
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
            // make sure scroll bar shows
            overflowY: 'scroll'
          },
          suggestion: {
            minWidth: '300px'
          }
        }}
        renderSuggestion={(
          suggestion: SuggestionType,
          { query, isHighlighted }: { isHighlighted: boolean; query: string }
        ) => {
          const parts = parse(suggestion.title, match(suggestion.title, query));

          let image: React.ReactNode = null;
          if (suggestion.image) {
            image = (
              <Avatar
                variant="square"
                alt={suggestion.title}
                src={suggestion.image}
              />
            );
          } else if (suggestion.isAdmin) {
            image = (
              <Avatar>
                <Lock />
              </Avatar>
            );
          } else if (suggestion.url.includes('amazon-coupon-deals')) {
            image = (
              <Avatar>
                <Sell />
              </Avatar>
            );
          } else if (suggestion.url.includes('/p/')) {
            image = (
              <Avatar>
                <ViewList />
              </Avatar>
            );
          }

          return (
            <ListItem
              selected={isHighlighted}
              component="div"
              dense
              sx={{ cursor: 'pointer' }}
            >
              {image && <ListItemAvatar>{image}</ListItemAvatar>}
              <ListItemText
                primary={
                  <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {parts.map((part, index) =>
                        part.highlight ? (
                          <span key={String(index)} style={highlightedStyle}>
                            {part.text}
                          </span>
                        ) : (
                          <strong
                            key={String(index)}
                            style={unHighlightedStyle}
                          >
                            {part.text}
                          </strong>
                        )
                      )}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        }}
        containerProps={{ style: { width: '100%' } }}
      />
    </Box>
  );
};

export default SearchBox;
