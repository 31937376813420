import React, { useState } from 'react';
import { Typography, Popover, Box } from '@mui/material';

const LiveUpdates = ({ showText }: { showText: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ paddingBottom: '12px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '15px',
          fontWeight: 700,
          textTransform: 'uppercase',
          letterSpacing: '2px',
          lineHeight: 1,
          '@media (max-width: 48rem)': {
            alignItems: 'center'
          }
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box sx={{ padding: '0 0.7em 1px 0', display: 'inline-block' }}>
          <Box
            sx={{
              position: 'relative',
              zIndex: 1,
              animation: 'updateAnime 2s infinite',
              willChange: 'transform, opacity',
              '&:after': {
                content: '""',
                display: 'block',
                borderRadius: '50%',
                width: '0.9em',
                height: '0.9em',
                backgroundColor: '#c33'
              },
              '@keyframes updateAnime': {
                '0%': { opacity: 0, transform: 'scale(0.9)' },
                '50%': { opacity: 1, transform: 'scale(1)' },
                to: { opacity: 0, transform: 'scale(0.9)' }
              },
              ...(showText ? {} : { display: 'none' })
            }}
          />
        </Box>
        <span style={showText ? {} : { display: 'none' }}>Live Updates</span>

        <Box>
          <Popover
            id="mouse-over-popover"
            sx={{ pointerEvents: 'none' }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 2 }}>
              This page will automatically live update with the newest deals.
            </Typography>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

export default LiveUpdates;
