/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import emojiStrip from 'emoji-strip';
import config from '@configFile';
import { DealPostType } from '../types';
import extraDealMap from './extraDealMapper/map';

export const addAffiliateTagToLink = (
  link: string,
  tag: string = config.AFFILIATE_TAGS.DEFAULT
) => {
  if (link.includes('tag=')) {
    // replace affiliate tag if it exists
    return link.replace(/tag=[^&]*/, `tag=${tag}`);
  }

  if (link.includes('?')) {
    return `${link}&tag=${tag}`;
  }

  return `${link}?tag=${tag}`;
};

export const replaceNumLiveDeals = (
  template: string,
  numLiveDeals: number | undefined
): string => {
  return template.replace('{numLiveDeals}', `${numLiveDeals || 'Lots of'}`);
};

export const replaceNumLiveDealsImages = (template: string): string => {
  return template.replace('{numLiveDeals}', `${'Lots of'}`);
};

export const removeUnwantedFromGeneratedPostText = (text: string = '') => {
  return emojiStrip(text)
    .replace('Paragraph 1:', '')
    .replace('Paragraph 2:', '')
    .replace('Paragraph 1', '')
    .replace('Paragraph 2', '')
    .trim();
};

export function sanitizeFeature(_input: string): string {
  // console.log('feature unsanitized: ', _input);
  const input = emojiStrip(_input);
  return input
    .replace(/é/g, 'e') // Replace accented 'é' with 'e'
    .replace(/ê/g, 'e') // Replace accented 'é' with 'e'
    .replace(/è/g, 'e') // Replace accented 'é' with 'e'
    .replace(/á/g, 'a') // Replace accented 'é' with 'e'
    .replace(/á/g, 'a') // Replace accented 'é' with 'e'
    .replace(/â/g, 'a') // Replace accented 'é' with 'e'
    .replace(/ä/g, 'a') // Replace accented 'é' with 'e'
    .replace(/ï/g, 'i') // Replace accented 'é' with 'e'
    .replace(/î/g, 'i') // Replace accented 'é' with 'e'
    .replace(/ü/g, 'u') // Replace accented 'é' with 'e'
    .replace(/û/g, 'u') // Replace accented 'é' with 'e'
    .replace(/ó/g, 'o') // Replace accented 'é' with 'e'
    .replace(/Ã©/g, 'e') // Incorrect character with e
    .replace(/ñ/g, 'n') // Replace curly apostrophes with straight ones
    .replace(/’/g, "'") // Replace curly apostrophes with straight ones
    .replace(/[\u{1F600}-\u{1F6FF}]/gu, '') // Remove emojis
    .replace(/[^a-zA-Z0-9\s/\-–—:"'`•,*&<>$#@+()[/\]?$.!]/g, '') // Allow specific characters and remove others
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .trim(); // Remove leading and trailing whitespace
}

export const getBrandsString = (brands: string[]): string => {
  const uniqueBrands = _.uniq(brands);

  if (uniqueBrands.length === 1) {
    return uniqueBrands[0];
  }

  const totalBrands = uniqueBrands.length;
  const brandsToShow = totalBrands > 3 ? 3 : totalBrands;
  const limitedBrands = uniqueBrands.slice(0, brandsToShow);

  if (totalBrands <= 3) {
    return `${limitedBrands.slice(0, -1).join(', ')} & ${limitedBrands.slice(
      -1
    )}`;
  }

  return `${limitedBrands.join(', ')} & more`;
};

export const getQtyString = (
  props: Pick<DealPostType, 'finalPrice' | 'qtyTerm' | 'qty'>
) => {
  const { qty, qtyTerm, finalPrice } = props;
  if (
    !qty ||
    !qtyTerm ||
    typeof qty !== 'number' ||
    typeof qtyTerm !== 'string'
  ) {
    return '';
  }

  return ` or ${formatPrice(finalPrice / qty)}/${qtyTerm}`;
};

// TODO: consolidate with existing function for this
export const shouldShowFinalPrice = (
  props: Pick<DealPostType, 'finalPrice' | 'listPrice'>
): boolean => {
  const { listPrice, finalPrice } = props;
  if (!finalPrice || finalPrice === 0) {
    return false;
  }
  if (listPrice && finalPrice > listPrice) {
    return false;
  }

  return true;
};

export const shouldShowListPrice = (
  props: Pick<DealPostType, 'finalPrice' | 'listPrice'>
): boolean => {
  const { listPrice, finalPrice } = props;

  if (
    !listPrice ||
    !finalPrice ||
    listPrice === finalPrice ||
    finalPrice > listPrice ||
    listPrice === 0
  ) {
    return false;
  }

  // TODO: put this in config file
  // must be within 15% to show the list price
  const thresholdToShowListPriceClose = 1.15 * finalPrice;

  if (thresholdToShowListPriceClose >= listPrice) {
    return false;
  }

  return true;
};

interface ListPriceOptions {
  showReg?: boolean;
}

export const renderListPrice = (
  listPrice: number,
  options: ListPriceOptions = {}
): string => {
  const { showReg = true } = options;
  if (!listPrice) {
    return '';
  }

  if (showReg) {
    return ` (reg. ${formatPrice(listPrice)})`;
  }
  return `${formatPrice(listPrice)}`;
};

export const isValidEmail = (email: string) => {
  if (!(email && typeof email === 'string')) {
    return false;
  }
  const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return re.test(email);
};

export const formatNumberToThousands = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPrice = (price: number): string => {
  if (Number.isNaN(price) || !price?.toFixed) return '';
  if (price <= 0.995) {
    const centPrice = Math.round(100 * Number(price.toFixed(2)));
    return `${centPrice}¢`;
  }

  return `$${price.toFixed(2)}`;
};

export const lowercaseFirstLetter = (word: string) => {
  return word.charAt(0).toLowerCase() + word.slice(1);
};

export const getListPrice = (props: DealPostType): string => {
  const showListPrice = shouldShowListPrice(props);
  const { listPrice } = props;
  if (showListPrice) {
    return renderListPrice(listPrice, { showReg: false });
  }
  return '';
};

// This just formats the extra deal string to be more consistent to display
const normalizeExtraDealStr = (extraDeal: string): string => {
  return (extraDeal || '')
    .toLowerCase()
    .trim()
    .replace(/!|\./g, '')
    .replace('savings ', '');
};

interface PostPriceOptions {
  showReg?: boolean;
  qualifier?: string;
  showPromotion?: boolean;
}

export const getPostPrice = (
  props: DealPostType,
  showShipped = true,
  {
    showReg = true,
    qualifier = 'as low as',
    showPromotion = true
  }: PostPriceOptions = {}
): string => {
  const {
    ss,
    finalPrice,
    listPrice,
    qty,
    qtyTerm,
    extraDealPriceEach,
    extraDeal
  } = props;

  if (finalPrice < 0) {
    return '';
  }

  const normalizedDeal = normalizeExtraDealStr(extraDeal);
  let extraDealEachStr = `/each`;

  const dealConfig = extraDealMap[normalizedDeal];

  // Process deal if it exists in the map.
  // set eachStr to empty string if the deal is a "buy x get y" deal
  if (dealConfig?.x && dealConfig?.x === 1) {
    extraDealEachStr = '';
  }

  const hasExtraDealPriceDeal = extraDealPriceEach && extraDeal;
  const formatedEDPrice = formatPrice(extraDealPriceEach || 0);
  const extraDealText = lowercaseFirstLetter(extraDeal || '');
  const extraDealPriceString = ` or ${qualifier} ${formatedEDPrice}${extraDealEachStr} with deal ${extraDealText}`;
  const extra =
    hasExtraDealPriceDeal && showPromotion ? extraDealPriceString : '';
  const leadStr = ss !== 1 ? ' ONLY ' : ` ${qualifier} `;
  if (finalPrice) {
    const hasQty = qty && qtyTerm;
    const qtyStr = hasQty
      ? ` or ${formatPrice(finalPrice / qty)}/${qtyTerm}`
      : '';
    const showListPrice = showReg && shouldShowListPrice(props);
    const listPriceBlock = showListPrice ? `${renderListPrice(listPrice)}` : '';
    const lastPart = showShipped ? ` shipped!` : '';
    const onlyPart = shouldShowListPrice(props) ? '' : 'ONLY ';
    const possibleLeadPart = ss !== 1 ? onlyPart : `${qualifier} `;
    const leadPart = showShipped ? leadStr : possibleLeadPart;
    const formattedFinalPrice = formatPrice(finalPrice);
    return `${leadPart}${formattedFinalPrice}${qtyStr}${listPriceBlock}${extra}${lastPart}`;
  }
  return '';
};
